import * as React from "react"

import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <Layout>
    <div className="container" style={{ marginTop: "90px", maxWidth: "620px" }}>
      <section className="section">
        <div className="content has-text-centered">
          <h4>お探しのページが見つかりません</h4>
          <div className="has-text-left">
            <p>
              アクセスしたページは削除、変更されたか、現在利用できない可能性があります。
            </p>
            <p>ブラウザバック、もしくは画面上部メニューからお戻りください。</p>
            <p>
              お戻りにならない方は、パンからはみ出るチーズをお楽しみください。
            </p>
          </div>
          <StaticImage
            src="../images/bread-cheese.jpg"
            placeholder="none"
            height={400}
            alt="cheese"
            className="my-4"
          />
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
